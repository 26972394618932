//--------------------------------------------------------------
// BACK TO TOP
//--------------------------------------------------------------

const back = document.querySelector('[data-js-top]');

function backToTop(e) {
	// If the window height is not at the very top.
	if (window.pageYOffset > 0) {
		// X, Y - Scroll the page to the top.
		window.scrollTo(0, 0);
	}
}

if (back) {
	back.addEventListener('click', backToTop);
}
