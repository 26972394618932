//--------------------------------------------------------------
// MENU
//--------------------------------------------------------------

const menuTrigger = document.querySelector('[data-js-menu-trigger]');
const menu = document.querySelector('[data-js-menu]');

function toggleMenu() {
	menu.classList.toggle('is-active');
}

if (menuTrigger && menu) {
	menuTrigger.addEventListener('click', toggleMenu);
}

// Add More Dots
const more = document.querySelector('.navigation__item--more a');

if (more) {
	const dots = document.createElement('span');
	dots.classList.add('fas', 'fa-ellipsis-h', 'more-dots');
	more.insertAdjacentElement('beforeend', dots);
}
