//--------------------------------------------------------------
// GALLERY
//--------------------------------------------------------------

import 'slick-carousel';

$('[data-js-gallery]').slick({
	slidesToShow: 2,
	responsive: [
		{
			breakpoint: 1180,
			settings: {
				slidesToShow: 1,
			},
		},
	],
});

// Add icons.
$('.slick-prev').append('<span class="fas fa-chevron-left"></span>');
$('.slick-next').append('<span class="fas fa-chevron-right"></span>');
